<template>
  <div ref="tree" class="mygoods_zong">
    <div class="head">
      <!-- 头部tab栏 -->
      <div class="hd_tab">
        <div
          v-for="(item, index) in head_list"
          :key="index"
          :class="tab === item.id ? 'head_item_click' : 'head_item'"
          class="head_item"
          @click="change_item(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 中间搜索框 -->
      <div class="hd_search">
        <div class="search_box">
          <div class="txt">商品名称/id</div>
          <input class="inp" v-model="shop_title" />
        </div>
        <div class="search_box">
          <div class="txt"></div>
          <el-button class="search_btn" @click="sub_search">搜索</el-button>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- 批量操作商品 -->
    <div class="goods_edit">
      <el-button
        class="edit_box"
        @click="show_ds(2)"
        :disabled="agree_Compensation"
        :class="agree_Compensation === false ? 'edit_box1' : 'edit_box'"
      >
        强制下架商品
      </el-button>
      <div class="edit_hou">已选商品<span v-if="goods_len > 0">({{ goods_len }})</span></div>
    </div>
    <!-- 商品列表 -->
    <div class="kkk">
      <table class="goods_list">
        <!-- 表头 -->
        <thead class="list_hd">
          <th class="one">
            <el-checkbox
              class="checkbox"
              v-model="checkedAll"
              @change="handleCheckAllChange"
            >
              商品
            </el-checkbox>
          </th>
          <th class="it">价格</th>
          <th class="it">库存</th>
          <!-- <th class="it">销量</th> -->
          <th class="it">创建时间</th>
          <th class="it">操作</th>
        </thead>
        <!-- 表单内容 -->
        <tbody class="list_text">
          <tr
            v-for="(item, index) in shop_list"
            :key="index"
            class="tr"
            style="border: 1px solid #e8e8e8"
          >
            <td class="gds_item">
              <el-checkbox class="checkbox" v-model="item.v1" @change="hra($event,index)">
                <!-- <img class="goods_tu" :src="item.img_src" /> -->
                <el-image
                  class="goods_tu"
                  :src="item.img_src"
                  :fit="'cover'"
                  :preview-src-list="item.img_list"
                  @click.prevent="a"
                ></el-image>
              </el-checkbox>
              <div class="goods_info">
                <div class="Trade_name">{{ item.title }}</div>
                <div class="goods_id">ID:{{ item.id }}</div>
                <div class="di">
                  <div class="type">{{ item.type_title }}</div>
                  <div class="type">{{ item.brand }}</div>
                  <div class="zhe" v-if="item.reduction == 1">折</div>
                  <div class="baoyou" v-if="item.freight == 0">包邮</div>
                </div>
              </div>
            </td>
            <td>
              <div class="price">￥{{ item.price }}</div>
            </td>
            <td>
              <div class="stock">{{ item.total }}</div>
            </td>
            <!-- <td>
                            <div class="sales_volume">186</div>
                        </td> -->
            <td>
              <div class="creat_time">
                <div class="time_txt">{{ item.create_time }}</div>
                <div class="goods_type" v-if="item.status == 0">正在审核中</div>
                <div class="goods_type"  style="color: red" v-else-if="item.status == -1">已被强制下架</div>
                <template v-else-if="item.status == 2">
                  <div class="goods_red">审核不通过</div>
                  <div class="goods_type1">原因：{{ item.reason }}</div>
                </template>
                <template v-else>
                  <template v-if="item.total > 0">
                    <div class="goods_type" v-if="item.shelves == 0">
                      出售中
                    </div>
                    <div class="goods_type1" v-if="item.shelves == 2">
                      仓库中
                    </div>
                    <div class="goods_type2" v-if="item.shelves == 1">预售</div>
                  </template>
                  <template v-else>
                    <div class="goods_type" style="color: red">库存不足</div>
                  </template>
                </template>
              </div>
            </td>
            <td>
              <template v-if="item.status == 1">
                <template v-if="item.shelves == 0 || item.shelves == 1">
                  <span
                    class="form-btn btn-xs btn-danger"
                    @click="qzxj_comfirm(item.id)"
                    >强制下架商品</span
                  >
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 底部分页 -->
    <page-tab
      :page="page"
      :count="page_count"
      @change="change_page"
    ></page-tab>

    <!-- 背景虚影 -->
    <div v-if="is_ds">
      <div class="goods_bg"></div>
      <div class="ca_box">
        <div class="ca_title" v-if="number == 2">下架商品</div>
        <div class="ca_txt" v-if="number == 2">确定要下架商品吗？</div>
        <div class="ft_btn">
          <div class="cancle" @click="show_ds">取消</div>
          <div class="comfirm" @click="xjia_comfirm">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../common/axios";
import pageTab from "../pages/index";

export default {
  components: {
    pageTab,
  },
  props: ["act"],
  data() {
    return {
      tab: 0, //选择宝贝状态
      value: "", // 订单状态选中的值
      checkedAll: false, //商品多选框
      is_show_checked1: false,
      number: "", //判断是上架商品/下架商品/删除商品
      is_ds: false, //是否下架商品
      goods_type: 2, //商品状态
      is_cr: 0, //判断是否是从创建优惠活动页面跳转过来的
      agree_Compensation: true, // 控制头部按钮是否禁用
      page: 1,
      page_count: 1,
      shop_title: "",
      change_sta: false,

      head_list: [
        {
          id: 0,
          name: "全部宝贝",
        },
        {
          id: 1,
          name: "出售中的宝贝",
        },
        {
          id: 2,
          name: "仓库中的宝贝",
        },
      ],
      options: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "出售中",
        },
        {
          value: 2,
          label: "仓库中",
        },
      ],
      shop_list: [],
      goods_len: 0,
    };
  },
  mounted() {
    this.get_goods();
  },
  methods: {
    qzxj_comfirm(id){
      this.$confirm("确定要强制下架该商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/adminshop/goods/goods_mandat.html";
          let data = {
            sid:id
          };
          axios.post(url, data).then((res) => {
            let success = res.success;
            let reslut = res.data;
            if (success) {
              if (reslut.status == 1) {
                this.$message({
                  message: reslut.msg,
                  type: "success",
                });
                this.get_goods(this.page);
              } else {
                this.$message.error(reslut.msg);
              }
            }
          });
        })
        .catch(() => {});
    },
    xjia_comfirm(){
      let a = [];
      this.shop_list.map((item) => {
        if (item.v1 == true) {
          a.push(item.id);
        }
      });
      a = a.toString();
        let data = {
          sid:a,
        };
        let url = "/adminshop/goods/goods_mandat.html"
        axios.post(url, data).then((res) => {
          let success = res.success;
          let data = res.data;
          if (success) {
            if (data.status == 1) {
              console.log(data);
              this.$message({
                message: data.msg,
                type: 'success'
              })
              this.show_ds()
              this.get_goods(this.page)
              this.agree_Compensation = true
              this.goods_len = 0
              this.checkedAll = false
            } else {
               this.$message({
                message: data.msg,
                type: 'error'
              })
            }
          } else {
            console.log(res.error);
          }
        });
    },
    jump(e) {
      let rou = this.$router.resolve({
        name: "/goods_view",
        params: {
          id: e,
        },
      });
      window.open(rou.href, "_blank");
    },
    sub_search() {
      this.page = 1;
      this.get_goods();
    },
    get_goods(page = 0) {
      if (page == 0) {
        page = 1;
      }
      let title = this.shop_title;
      let status = this.value;
      this.page = page;
      let shop_list = [];
      let url = "/adminshop/goods/index.html";
      let data = {
        page,
        title,
        status,
      };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          let list = data.list;
          if (list != undefined && list != null && list != "") {
            for (var i in list) {
              shop_list[i] = list[i];
              shop_list[i].v1 = false;
            }
            // shop_list = list;
          }
          this.page_count = parseInt(data.pages);
        } else {
          console.log(res.error);
        }
        this.shop_list = shop_list;
      });
    },
    change_item(id) {
      if (this.tab == id) {
        return;
      }
      this.change_sta = false;
      if (id > 0) {
        this.change_sta = true;
      }
      this.tab = id;
      this.value = id;
      this.page = 1;
      this.get_goods();
      this.$refs.tree.scrollTop = 0
      this.checkedAll = false;
      this.agree_Compensation = true;
      this.goods_len = 0;
    },
    change_edit(index) {
      console.log("index", index);
      this.tab1 = index;
    },
    change_page(e) {
      let page = e.value;
      this.page = page;
      this.get_goods(page);
      this.$refs.tree.scrollTop = 0
      this.checkedAll = false;
      this.agree_Compensation = true;
      this.goods_len = 0;
    },
    show_ds(num) {
      this.is_ds = !this.is_ds;
      this.number = num;
    },
    handleCheckAllChange(e) {
       this.checkedAll = e;
      if (this.checkedAll == true) {
        this.shop_list.forEach((item) => {
          this.$set(item, "v1", true);
          this.agree_Compensation = false;
          this.goods_len = this.shop_list.length;
        });
      } else {
        this.shop_list.forEach((item) => {
          this.$set(item, "v1", false);
          this.agree_Compensation = true;
          this.goods_len = 0;
        });
      }
    },
    hra(e,index) {
      //选中其中一个退款订单，触发相对应订单能操作的按钮
      // this.agree_Compensation = !this.agree_Compensation;
       this.shop_list[index].v1 = e;
      let a = this.shop_list.filter((item) => item.v1 == true).length;
      console.log(a);
      this.goods_len = a;
      if (a > 0) {
        this.agree_Compensation = false;
      } else {
        this.agree_Compensation = true;
      }
      if (a == this.shop_list.length) {
        this.checkedAll = true;
      } else {
        this.checkedAll = false;
      }
    },
    a(){},
  },
};
</script>

<style scoped>
@import "./mygoods.css";

.bba /deep/ .el-dialog__header {
  padding: 15px 0;
}

.bba /deep/ .el-dialog__title {
  font-weight: bold;
  line-height: 0;
}
</style>
